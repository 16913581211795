<template>
  <div
    class="bitts-table__column-header"
    :class="{
      'cursor-pointer': params.enableSorting,
      'cursor-default': !params.enableSorting,
      'manual-sort-ascending': showManualDescSort,
    }"
    @click="onSortRequested"
  >
    <div v-if="!!params.showCheckbox">
      <BittsCheckbox
        class="mr-12"
        :checked="params.checked"
        :indeterminate="params.indeterminate"
        @click.stop
        @input="(bool) => params.onChecked(bool)"
      />
    </div>
    <div
      :class="
        sortEnabled || manualSortEnabled
          ? 'text-secondary-text'
          : 'text-neutral-strong'
      "
      class="mr-4 truncate"
    >
      <div class="flex items-center justify-between">
        <BittsSvg
          v-if="params.icon && typeof params.icon === 'string'"
          :svg="params.icon"
          class="h-16 w-16 mr-2"
        />
        <FontAwesomeIcon
          v-else-if="params.icon"
          :icon="params.icon"
          class="h-16 w-16 mr-2"
        />
        <BittsTooltip
          v-if="params.showTooltip"
          :mount-to-body="true"
          placement="bottom"
          class="max-w-content truncate"
        >
          <p class="truncate">
            {{ params.displayName }}
          </p>
          <template #title>
            <span>{{ params.displayName }}</span>
          </template>
        </BittsTooltip>
        <span v-else>{{ params.displayName }}</span>
      </div>
    </div>
    <div
      v-if="
        (params.showArrow && !sortEnabled) ||
        (params.onManualSort && !manualSortEnabled)
      "
    >
      <FontAwesomeIcon
        class="c-bitts-table__sort-toggle text-neutral-accent"
        :icon="['fak', 'arrow-down']"
        :style="{ height: '12px', width: '12px' }"
      />
    </div>
    <div
      v-if="(params.enableSorting && sortEnabled) || manualSortEnabled"
      class="text-secondary-accent"
    >
      <FontAwesomeIcon
        class="c-bitts-table__sort-toggle"
        :icon="['fak', 'arrow-down']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import BittsCheckbox from '../BittsCheckbox/BittsCheckbox.vue';
import BittsSvg from '../BittsSvg/BittsSvg.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const ascSort = ref(null);
const descSort = ref(null);
const noSort = ref(null);

const route = useRoute();

const ASC = 'asc';
const DESC = 'desc';

const sortEnabled = computed(() => ascSort.value || descSort.value);
const manualSortSameCol = computed(() => {
  return route?.query?.sort === props.params.column.colId;
});

const showManualDescSort = computed(() => {
  return manualSortSameCol.value && route?.query?.order === ASC;
});

const showManualAscSort = computed(() => {
  return manualSortSameCol.value && route?.query?.order === DESC;
});

const manualSortEnabled = computed(() => {
  return showManualDescSort.value || showManualAscSort.value;
});

function onManualSortChanged(init = null) {
  /* This event listener callback is triggered on first page load.
      We only want to trigger the onManualSort when a user clicks a sortable header cell.
      The results of an existing query or no query at all on page load will already be present from response */
  if (init) return;

  // params.column is the column that was clicked
  const payload = {
    sort: props.params.column.colId,
  };

  payload.order = showManualAscSort.value ? ASC : DESC;

  props.params.onManualSort(payload);
}

function onSortRequested() {
  if (!props.params.enableSorting) return;
  if (props.params.onManualSort) onManualSortChanged();
  else props.params.progressSort();
}

function onSortChanged() {
  ascSort.value = descSort.value = noSort.value = false;
  if (props.params.column.isSortAscending()) {
    ascSort.value = true;
  } else if (props.params.column.isSortDescending()) {
    descSort.value = true;
  } else {
    noSort.value = true;
  }
}

onMounted(() => {
  const { enableSorting, onManualSort, column } = props.params;

  if (enableSorting) {
    /* onManualSort is a function that is passed in from the parent via column definitions
  (columns prop passed to BittsTable). It is used to handle manual sorting via router
  If onManualSort is passed in, we need to add a listener for manual sort. Otherwise, we add a listener for default sort.
  The onManualSort passes the { sort, order } payload to the parent. */
    const eventListener = onManualSort ? onManualSortChanged : onSortChanged;
    column.addEventListener('sortChanged', eventListener);
    eventListener(onManualSort);
  }
});

onBeforeUnmount(() => {
  props.params.column.removeEventListener('sortChanged', onSortChanged);
  if (props.params.onManualSort)
    props.params.column.removeEventListener('sortChanged', onManualSortChanged);
});
</script>
